import Velocity from 'velocity-animate';


function set_active_item() {
    const self = this;
    if (self.classList.contains('active')) {
        return;
    }

    const active = self.closest('ul').querySelector('.active');
    const active_crop = active.querySelector('.bwg-acc-crop');

    const next_crop = self.querySelector('.bwg-acc-crop');
    const next_data = next_crop.querySelector('.bwg-acc-content');

    active.classList.remove('active');
    active_crop.velocity({
        height: '0px',
    }, {
        duration: 250
    });
    self.classList.add('active');
    next_crop.velocity({
        height: `${next_data.clientHeight}px`,
    }, {duration: 250});
}


function list_accordion() {
    document.querySelectorAll('.js-text-accordion li').forEach((node) => {
        node.addEventListener('click', set_active_item);
    });
}

export { list_accordion };
