/* global Swiper */


function review_gallery() {
    const list = document.querySelectorAll('.review-slider');
    list.forEach((node) => {
        const swiper = new Swiper(node, {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            // loop: true,
            // autoHeight: true,
            navigation: {
              nextEl: node.closest('.bwg-media-wrap').querySelector('.swiper-button-next'),
              prevEl: node.closest('.bwg-media-wrap').querySelector('.swiper-button-prev'),
            },
        });
    });
}

export { review_gallery };
