import { big_gallery } from './swipers/big_gallery.js';
import { box_with_gallery } from './swipers/box_with_gallery.js';
import { review_gallery } from './swipers/review_gallery.js';

import { btn_goto } from './btn-goto.js';
import { link_cookie } from './link-cookie.js';
import { list_accordion } from './list-accordion.js';


function init_app() {
    document.addEventListener('DOMContentLoaded', () => {
        big_gallery();
        box_with_gallery();
        review_gallery();

        btn_goto();
        link_cookie();
        list_accordion();
    });
}

export { init_app };
