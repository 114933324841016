/* global Swiper */

import imagesLoaded from 'imagesloaded';

function big_gallery() {
    const block = document.querySelector('.swiper-gallery');

    if (!block) {
        return;
    }
    const gallery = new Swiper(block, {
        effect: 'cards',
        grabCursor: true,
        loop: true,
    });

    const btn_next = block.querySelector('.swiper-button-next');
    const btn_prev = block.querySelector('.swiper-button-prev');

    if (btn_next) {
        btn_next.addEventListener('click', () => gallery.slideNext());
    }
    if (btn_prev) {
        btn_prev.addEventListener('click', () => gallery.slidePrev());
    }

    imagesLoaded(block, function(instance) {
        block.classList.remove('swiper-gallery_hidden');
    });
}

export { big_gallery };
