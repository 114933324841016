
function getCoords(elem) {
    let box = elem.getBoundingClientRect();
    return {
       top: box.top + window.pageYOffset,
       left: box.left + window.pageXOffset
    };
}

function goto_box() {
    const elem = document.getElementById(this.dataset.goto);
    if (!elem) {
        return;
    }
    window.scrollTo({
        top: getCoords(elem).top,
        behavior: 'smooth'
    });
}


function btn_goto() {
    document.querySelectorAll('[data-goto]').forEach((node) => {
        node.addEventListener('click', goto_box);
    });
}

export { btn_goto };
